<template>
  <div class="video-background">
    <video autoplay muted loop id="video-background">
      <source :src="videoSource" type="video/mp4" />
      Tu navegador no soporta la etiqueta de video.
    </video>
  </div>
</template>

<script>

export default {
  name: "VideoBackComponent",
    props:{
        videoSource: String,
    },

}
</script>

<style scoped>

.video-background {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  overflow: hidden;
  z-index: -1;
}

video {
  width: 100%;
  height: 100%;
  object-fit: cover;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

</style>