<template>
  <div :class="titleContainerStyle">
    <h1 :class="mainTitleStyle">{{ mainTitle }}</h1>
    <p :class="subtitleStyle">{{ subtitle }}</p>
  </div>
</template>

<script>
export default {
  name: "TitleComponent",
  props: {
    titleContainerStyle: {
      type: String,
    
    },
    mainTitleStyle: {
      type: String,
     
    },
    subtitleStyle: {
      type: String,
    
    },
    mainTitle: {
      type: String,
      required: true,
    },
    subtitle: {
      type: String,
     
    },
  },
};
</script>

<style scoped>
/* @import url('https://fonts.googleapis.comhttps://fonts.googleapis.com/css2?family=Antonio:wght@100&display=swap/css2?family=Anton&display=swap'); */

/*Estilos de IndexHome*/
.titlecontainerstylehome {
  display: flex;
  flex-direction: column;
  font-family: "Antonio", sans-serif;
  text-align: right;
  margin: 5% 5% 0% 50%;
  background-color: rgba(49, 57, 57, 0);
}

.maintitlestylehome {
  font-size: 5rem;
  margin-bottom: 10px;
  background-color: rgba(0, 255, 255, 0);
  color: #f1f0ec; /* Puedes ajustar el color según tu diseño */
}

.subtitlestylehome {
  font-size: 1.2rem;
  text-align: left;
  color: #fff7f7; /* Puedes ajustar el color según tu diseño */
}
@media screen and (max-width: 600px) {
  h1 {
    font-size: 200px;
    line-height: 5rem;
    letter-spacing: normal; /* Reducir el tamaño de fuente para pantallas más pequeñas */
  }
}

@media screen and (max-width: 1000px) {
  .titlecontainerstylehome {
    text-align: center;
    margin-top: 20%;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: auto;
    background-color: rgba(49, 57, 57, 0);
    border-radius: 20pxs solid green;
    border-color: yellow;
  }

  .maintitlestylehome {
    font-size: 4rem;
    line-height: 3.5rem;
    margin-bottom: auto;
    letter-spacing: normal;
    color: #f1f0ec; /* Puedes ajustar el color según tu diseño */
  }
}

/*Estilos de AboutUs*/

.titlecontainerstyleabout {
  display: flex;
  flex-direction: column;
  font-family: "Antonio", sans-serif;
  text-align: center;
  margin: 0% 0% 0% 0%;
  background-color: rgba(49, 57, 57, 0);
}

.maintitlestyleabout {
  font-size: 5rem;
  margin-bottom: 10px;
  background-color: rgba(0, 255, 255, 0);
  color: #f1f0ec; /* Puedes ajustar el color según tu diseño */
}

.subtitlestyleabout {
  text-align: center;
  font-size: 2rem;
  color: #fff7f7; /* Puedes ajustar el color según tu diseño */
}
@media screen and (max-width: 600px) {
  h1 {
    font-size: 200px;
    line-height: 5rem;
    letter-spacing: normal; /* Reducir el tamaño de fuente para pantallas más pequeñas */
  }
}

@media screen and (max-width: 1000px) {
  .titlecontainerstyleabout {
    text-align: center;
    margin-top: 0%;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: auto;
    background-color: rgba(49, 57, 57, 0);
    border-radius: 20pxs solid green;
    border-color: yellow;
  }

  .maintitlestyleabout {
    align-content: center;
    font-size: 4rem;
    line-height: 4rem;
    margin-bottom: auto;
    letter-spacing: normal;
    color: #f1f0ec; /* Puedes ajustar el color según tu diseño */
  }
}
</style>
