a
<template>
  <VideoBackComponentVue videoSource="/fondo2.mp4" />
  <NavBarVue logoSource="hellflip3.png" />
  <TitleComponentVue
    titleContainerStyle="titlecontainerstyleabout"
    mainTitleStyle="maintitlestyleabout"
    subtitleStyle="subtitlestyleabout"
    mainTitle="Clases"
    subtitle="Horario de Lunes a viernes de 17:00 a 18:00 y de 18:00 a 19:00"
  />
  <div class="horarios">
    <table>
      <thead>
        <tr>
          <th>Privadas</th>
          <th>Grupales</th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td>25€/Sesion</td>
          <td>50€/Mes</td>
        </tr>
      </tbody>
    </table>
  </div>

  <TitleComponentVue
    titleContainerStyle="titlecontainerstyleabout"
    mainTitleStyle="maintitlestyleabout"
    subtitleStyle="subtitlestyleabout"
    mainTitle="Síguenos"
    subtitle=""
  />
  <div class="rss">
    <div class="main">
      <div class="up">
        <a
          href="https://www.instagram.com/heelflipescuelaskategandia/"
          target="_blank"
          class="card1"
        >
          <button class="card1">
            <svg
              class="instagram"
              fill-rule="nonzero"
              height="30px"
              width="30px"
              viewBox="0,0,256,256"
              xmlns:xlink="http://www.w3.org/1999/xlink"
              xmlns="http://www.w3.org/2000/svg"
            >
              <g
                style="mix-blend-mode: normal"
                text-anchor="none"
                font-size="none"
                font-weight="none"
                font-family="none"
                stroke-dashoffset="0"
                stroke-dasharray=""
                stroke-miterlimit="10"
                stroke-linejoin="miter"
                stroke-linecap="butt"
                stroke-width="1"
                stroke="none"
                fill-rule="nonzero"
              >
                <g transform="scale(8,8)">
                  <path
                    d="M11.46875,5c-3.55078,0 -6.46875,2.91406 -6.46875,6.46875v9.0625c0,3.55078 2.91406,6.46875 6.46875,6.46875h9.0625c3.55078,0 6.46875,-2.91406 6.46875,-6.46875v-9.0625c0,-3.55078 -2.91406,-6.46875 -6.46875,-6.46875zM11.46875,7h9.0625c2.47266,0 4.46875,1.99609 4.46875,4.46875v9.0625c0,2.47266 -1.99609,4.46875 -4.46875,4.46875h-9.0625c-2.47266,0 -4.46875,-1.99609 -4.46875,-4.46875v-9.0625c0,-2.47266 1.99609,-4.46875 4.46875,-4.46875zM21.90625,9.1875c-0.50391,0 -0.90625,0.40234 -0.90625,0.90625c0,0.50391 0.40234,0.90625 0.90625,0.90625c0.50391,0 0.90625,-0.40234 0.90625,-0.90625c0,-0.50391 -0.40234,-0.90625 -0.90625,-0.90625zM16,10c-3.30078,0 -6,2.69922 -6,6c0,3.30078 2.69922,6 6,6c3.30078,0 6,-2.69922 6,-6c0,-3.30078 -2.69922,-6 -6,-6zM16,12c2.22266,0 4,1.77734 4,4c0,2.22266 -1.77734,4 -4,4c-2.22266,0 -4,-1.77734 -4,-4c0,-2.22266 1.77734,-4 4,-4z"
                  ></path>
                </g>
              </g>
            </svg>
          </button>
        </a>
        <button class="card2">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            height="24"
            class="facebook"
            width="24"
          >
            <path
              d="M9.19795 21.5H13.198V13.4901H16.8021L17.198 9.50977H13.198V7.5C13.198 6.94772 13.6457 6.5 14.198 6.5H17.198V2.5H14.198C11.4365 2.5 9.19795 4.73858 9.19795 7.5V9.50977H7.19795L6.80206 13.4901H9.19795V21.5Z"
            ></path>
          </svg>
        </button>
      </div>
      <div class="down">
        <a
          href="https://wa.me/+34602838760/?text=Hola%20me%20gustaria%20saber%20más%20sobre%20las%20clases%20de%20skateboard"
          target="_blank"
        >
          <button class="card3">
            <svg
              width="30"
              height="30"
              viewBox="0 0 24 24"
              xmlns="http://www.w3.org/2000/svg"
              class="whatsapp"
            >
              <path
                d="M19.001 4.908A9.817 9.817 0 0 0 11.992 2C6.534 2 2.085 6.448 2.08 11.908c0 1.748.458 3.45 1.321 4.956L2 22l5.255-1.377a9.916 9.916 0 0 0 4.737 1.206h.005c5.46 0 9.908-4.448 9.913-9.913A9.872 9.872 0 0 0 19 4.908h.001ZM11.992 20.15A8.216 8.216 0 0 1 7.797 19l-.3-.18-3.117.818.833-3.041-.196-.314a8.2 8.2 0 0 1-1.258-4.381c0-4.533 3.696-8.23 8.239-8.23a8.2 8.2 0 0 1 5.825 2.413 8.196 8.196 0 0 1 2.41 5.825c-.006 4.55-3.702 8.24-8.24 8.24Zm4.52-6.167c-.247-.124-1.463-.723-1.692-.808-.228-.08-.394-.123-.556.124-.166.246-.641.808-.784.969-.143.166-.29.185-.537.062-.247-.125-1.045-.385-1.99-1.23-.738-.657-1.232-1.47-1.38-1.716-.142-.247-.013-.38.11-.504.11-.11.247-.29.37-.432.126-.143.167-.248.248-.413.082-.167.043-.31-.018-.433-.063-.124-.557-1.345-.765-1.838-.2-.486-.404-.419-.557-.425-.142-.009-.309-.009-.475-.009a.911.911 0 0 0-.661.31c-.228.247-.864.845-.864 2.067 0 1.22.888 2.395 1.013 2.56.122.167 1.742 2.666 4.229 3.74.587.257 1.05.408 1.41.523.595.19 1.13.162 1.558.1.475-.072 1.464-.6 1.673-1.178.205-.58.205-1.075.142-1.18-.061-.104-.227-.165-.475-.29Z"
              ></path>
            </svg>
          </button>
        </a>
        <a
          href="mailto:escueladeskategandia@gmail.com?subject=Información sobre las clases de skate&body=Hola me gustaría recibir información"
        >
          <button class="card4">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              height="24"
              class="gmail"
              width="24"
            >
              <path
                d="M6 12C6 15.3137 8.68629 18 12 18C14.6124 18 16.8349 16.3304 17.6586 14H12V10H21.8047V14H21.8C20.8734 18.5645 16.8379 22 12 22C6.47715 22 2 17.5228 2 12C2 6.47715 6.47715 2 12 2C15.445 2 18.4831 3.742 20.2815 6.39318L17.0039 8.68815C15.9296 7.06812 14.0895 6 12 6C8.68629 6 6 8.68629 6 12Z"
              ></path>
            </svg>
          </button>
        </a>
      </div>
    </div>
  </div>
  <TitleComponentVue
    titleContainerStyle="titlecontainerstyleabout"
    mainTitleStyle="maintitlestyleabout"
    subtitleStyle="subtitlestyleabout"
    mainTitle="Estamos cerca de ti"
    subtitle=""
  />
  <div class="mapa">
    <iframe
      src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d1551.0938250136871!2d-0.1960914603271512!3d38.965383!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0xd61e9b7d76545a9%3A0x75ad12fc8e59e44e!2sSkatepark%20Gand%C3%ADa!5e0!3m2!1sen!2ses!4v1706961754163!5m2!1sen!2ses"
      width="100%"
      height="100%"
      style="border-radius: 20px; :0 "
      loading="lazy"
      referrerpolicy="no-referrer-when-downgrade"
    ></iframe>
  </div>
</template>

<script>
import NavBarVue from "@/components/NavBar.vue";
import VideoBackComponentVue from "@/components/VideoBackComponent.vue";
import TitleComponentVue from "@/components/TitleComponent.vue";

export default {
  name: "JoinUs",
  components: {
    VideoBackComponentVue,
    NavBarVue,
    TitleComponentVue,
  },
};
</script>

<style>
.rss {
  display: flex;
  justify-content: center;
}
.horarios {
  display: flex;
  justify-content: center;
  width: 100%;
}
.horarios table {
  border: 1px solid hsl(0, 0%, 92%);
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.907);
  color: #ddd;
  background-color: #000000;
  font-family: sans-serif;
  width: 100%;
}

td {
  border: 1px solid #191212;
  font-size: 1.2rem;
  text-align: center;
}

th {
  background-color: #0d0303;
  font-size: 2rem;
  text-align: center;
  border: white;
}

.mapa {
  margin: 0% 3% 0% 3%;
}
.main {
  display: flex;
  flex-direction: column;
  gap: 0.5em;
}

.up {
  display: flex;
  flex-direction: row;
  gap: 0.5em;
}

.down {
  display: flex;
  flex-direction: row;
  gap: 0.5em;
}

.card1 {
  width: 90px;
  height: 90px;
  outline: none;
  border: none;
  background: rgb(6, 6, 6);
  border-radius: 90px 5px 5px 5px;
  box-shadow: rgba(50, 50, 93, 0.25) 0px 2px 5px -1px,
    rgba(0, 0, 0, 0.3) 0px 1px 3px -1px;
  transition: 0.2s ease-in-out;
}

.instagram {
  margin-top: 1.5em;
  margin-left: 1.2em;
  fill: #cc39a4;
}

.card2 {
  width: 90px;
  height: 90px;
  outline: none;
  border: none;
  background: rgb(0, 0, 0);
  border-radius: 5px 90px 5px 5px;
  box-shadow: rgba(50, 50, 93, 0.25) 0px 2px 5px -1px,
    rgba(0, 0, 0, 0.3) 0px 1px 3px -1px;
  transition: 0.2s ease-in-out;
}

.facebook {
  margin-top: 1.5em;
  margin-left: -0.9em;
  fill: #03a9f4;
}

.card3 {
  width: 90px;
  height: 90px;
  outline: none;
  border: none;
  background: rgb(4, 4, 4);
  border-radius: 5px 5px 5px 90px;
  box-shadow: rgba(50, 50, 93, 0.25) 0px 2px 5px -1px,
    rgba(0, 0, 0, 0.3) 0px 1px 3px -1px;
  transition: 0.2s ease-in-out;
}

.whatsapp {
  margin-top: -0.6em;
  margin-left: 1.2em;
  fill: #00ff00;
}

.card4 {
  width: 90px;
  height: 90px;
  outline: none;
  border: none;
  background: rgb(0, 0, 0);
  border-radius: 5px 5px 90px 5px;
  box-shadow: rgba(50, 50, 93, 0.25) 0px 2px 5px -1px,
    rgba(0, 0, 0, 0.3) 0px 1px 3px -1px;
  transition: 0.2s ease-in-out;
}

.gmail {
  margin-top: -0.9em;
  margin-left: -1.2em;
  fill: #f14336;
}

.card1:hover {
  cursor: pointer;
  scale: 1.1;
  background-color: #cc39a4;
}

.card1:hover .instagram {
  fill: white;
}

.card2:hover {
  cursor: pointer;
  scale: 1.1;
  background-color: #1877f2;
}

.card2:hover .facebook {
  fill: white;
}

.card3:hover {
  cursor: pointer;
  scale: 1.1;
  background-color: #00ff00;
}

.card3:hover .whatsapp {
  fill: white;
}

.card4:hover {
  cursor: pointer;
  scale: 1.1;
  background-color: #ff0004;
}

.card4:hover .gmail {
  fill: white;
}
</style>
