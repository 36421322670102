<template>
  <video-back-component-vue videoSource="/fondo2.mp4" />
  <NavBarVue logoSource="hellflip3.png"></NavBarVue>
  <TitleComponentVue
    titleContainerStyle="titlecontainerstylehome"
    mainTitleStyle="maintitlestylehome"
    subtitleStyle="subtitlestylehome"
    mainTitle="!Escuela de skate en Gandia!"
    subtitle="Desde principiantes hasta expertos, nuestro sitio es el lugar perfecto para aprender, practicar y disfrutar del skateboarding."
    fontStyle="Anton"
  />
  <div class="buttons">
    <BotonConBordesVue
      msg="Únete"
      @click="$router.push('/joinus')"
    ></BotonConBordesVue>
    <BotonConBordesVue
      msg="Saber más"
      @click="$router.push('/aboutus')"
    ></BotonConBordesVue>
  </div>
  
<WhatsAppButton/>

</template>
<script>
import NavBarVue from "@/components/NavBar.vue";
import VideoBackComponentVue from "@/components/VideoBackComponent.vue";
import TitleComponentVue from "@/components/TitleComponent.vue";
import BotonConBordesVue from "@/components/BotonConBordes.vue";
import WhatsAppButton from "@/components/WhatsAppButton.vue";

export default {
  name: "HomeIndex",
  components: {
    VideoBackComponentVue,
    NavBarVue,
    TitleComponentVue,
    BotonConBordesVue,
    WhatsAppButton,
  },
};
</script>

<style>
.buttons {
  text-align: right;
  margin: 2% 7%;
  max-width: 100%;
}

@media screen and (max-width: 1000px) {
  .buttons {
  display: flex;
  text-align: center;
  padding: 0%;
  max-width: 100%;
}
}

</style>
