<template>
  <div><router-view></router-view></div>
</template>
<script>
export default {
  name: "App",
  created() {
    const script = document.createElement("script");
    script.async = true;
    script.src = "//www.instagram.com/embed.js";
    document.head.appendChild(script);
  },
};
</script>

<style>

</style>
