import { createApp } from "vue";
import App from "./App.vue";
import { createRouter, createWebHistory } from "vue-router";
import HomeIndex from "./views/IndexHome.vue";
import AboutUs from "./views/AboutUs.vue";
import JoinUs from "./views/JoinUs.vue";

const routes = [
  { path: "/", component: HomeIndex },
  { path: "/aboutus", component: AboutUs },
  { path: "/joinus", component: JoinUs },
  
  // Otras rutas...
];

const router = createRouter({
  history: createWebHistory(),
  base: "/home/u454236151",
  routes,
});

const app = createApp(App);
app.use(router);
app.mount("#app");
