<template>
  <div class="card">
    <img :src="imageUrl" alt="Imagen de la tarjeta" class="card-image" />
    <div class="card-content">
      <h2 class="card-title">{{ title }}</h2>
      <p class="card-description">{{ description }}</p>
    </div>
  </div>
</template>

<script>
export default {
  name: "CardComponent",
  props: {
    imageUrl: String,
    title: String,
    description: String,
  },
};
</script>

<style scoped>
.card {
  border: 1px solid #000000;
  border-radius: 10px;
  overflow: hidden;
  margin: 1rem;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.907);
  box-sizing: 20rem;
  width: 20rem;
  color: #ddd;
  background-color: #000000;
}

.card-image {
  width: 10%;
  height: auto;
  background-color: white;
}

.card-content {
  padding: 16px;
  font-family: sans-serif;
  background-color: #000000;
}

.card-title {
  font-size: 2rem;
  margin-top: 0%;
  margin-bottom: 8px;
  background-color: #000000;
}

.card-description {
  font-size: 1rem;
  color: white;
  background-color: #000000;
}
</style>
