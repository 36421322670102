<template>
  <button class="boton-con-bordes">
    <slot>{{ msg }}</slot>
  </button>
</template>

<script>
export default {
  name: "BotonConBordes",
  props: {
    msg: String,
  },
};
</script>

<style scoped>
button {
  position: relative;
  margin: 0% 1%;
  justify-content: space-between;
  background-color: rgb(0, 0, 0);
  border-radius: 25px;
  box-shadow: #f0ebed 0px 4px 0px 0px;
  background-repeat: no-repeat;
  cursor: pointer;
  box-sizing: border-box;
  width: 154px;
  height: 49px;
  color: #fff;
  border: none;
  font-size: 20px;
  transition: all 0.3s ease-in-out;
  z-index: 1;
  overflow: hidden;
}

button:hover {
  background-color: rgb(215, 206, 17);
}
</style>
