<template>
    <div class="whats">
      <a :href="whatsappLink" target="_blank">
        <img src="logoW.png" alt="Contactar por WhatsApp" />
      </a>
    </div>
  </template>
  
  <script>
  export default {
    data() {
      return {
        phoneNumber: '+34602838760', // Reemplaza con tu número de teléfono
        message: 'Hola, estoy interesad@ en las clases de skateboard', // Puedes cambiar el mensaje predefinido
      };
    },
    computed: {
      whatsappLink() {
        return `https://wa.me/${this.phoneNumber}/?text=${encodeURIComponent(this.message)}`;
      }
    }
  };
  </script>
  
  <style scoped>
  img {
    cursor: pointer;
    width: 5% ;
    /* Puedes agregar estilos adicionales según sea necesario */
  }
  @media screen and (max-width: 1000px) {

    .whats{
        display: flex;
        max-width: 100%;
        margin-top: 20%;
        text-align: center;
    }

    img {
    cursor: pointer;
    width: 20% ;
   
    /* Puedes agregar estilos adicionales según sea necesario */
  }
  }
  </style>
  