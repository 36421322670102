<template>
  <div class="instagram-container">
    <blockquote
      class="instagram-media"
      data-instgrm-captioned
      :data-instgrm-permalink="instagramPermalink"
      data-instgrm-version="14"
      style="
        background: #02000000;
        border: 0;
        border-radius: 10px;
        box-shadow: 0 0 1px 0 rgba(0, 0, 0, 0),
          0 1px 10px 0 rgba(0, 0, 0, 0);
        margin: 0%;
        max-width: 540px;
        min-width: 326px;
        padding: 0;
        width: 99.375%;
        width: -webkit-calc(100% - 2px);
        width: calc(100% - 2px);
      "
    >
      <div style="padding: 16px"></div>
    </blockquote>
  </div>
</template>

<script>
export default {
  data() {
    return {
      instagramPermalink:
        "https://www.instagram.com/reel/C2KpyAKIrmQ/?utm_source=ig_embed&utm_campaign=loading",
    };
  },
};

// Mueve la etiqueta <script> fuera del bloque template
// const script = document.createElement("script");
// script.async = true;
// script.src = "//www.instagram.com/embed.js";
// document.head.appendChild(script);
</script>

<style scoped>
.instagram-container {
  /* Estilo opcional para el contenedor del componente */
  align-content: center;
}

/* Puedes agregar estilos adicionales aquí si es necesario */
</style>
