<template>
  <div class="profesor-container">
    <img class="profesor-img" :src="foto" alt="Foto de Vicente Lloret" />

    <div class="profesor-bio">
      Es un apasionado del skateboarding con más de 10 años de experiencia. Su
      misión es ayudarte a mejorar tus habilidades sobre la tabla y disfrutar al
      máximo de este emocionante deporte.
    </div>
    <div class="profesor-habilidades">
      Habilidades destacadas: {{ habilidades }}
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      foto: "vicente.jpg",
      habilidades: "Trucos avanzados, técnicas de street y rampa.",
    };
  },
};
</script>

<style scoped>
/* Estilos para el componente */
.profesor-container {
  text-align: center;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.907);
  box-sizing: 20rem;
  width: 20rem;
  background-color: #000000;
  border-radius: 20px;
  font-family: sans-serif;
  padding: 3%;
  margin-bottom: 2%;
}

.profesor-img {
  width: 70%;
  border-radius: 0%;
  margin-bottom: 10px;
  margin-top: 10px;
}

.profesor-bio {
  font-size: 1.5rem;
  color: #fff8f8;
  margin-bottom: 10px;
}

.profesor-habilidades {
  font-size: 18px;
  font-style: italic;
  color: #ffffff;
}
</style>
