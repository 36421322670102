<template>
  <video-back-component-vue videoSource="/fondo2.mp4" />
  <NavBarVue logoSource="hellflip3.png"></NavBarVue>
  <TitleComponentVue
    titleContainerStyle="titlecontainerstyleabout"
    mainTitleStyle="maintitlestyleabout"
    subtitleStyle="subtitlestyleabout"
    mainTitle="¿Por que elegir el Skateboard?"
    subtitle=""
  />
  <div class="cards">
    <CardComponent
      title="Beneficios para la salud"
      description="El skate es una excelente forma de ejercicio que mejora la fuerza muscular,
    el equilibrio y la resistencia cardiovascular. "
      imageUrl="logo.png"
    />
    <CardComponent
      title="Desarrollo de habilidades"
      description="  El skate requiere habilidades motoras finas y gruesas, así como un alto
    grado de coordinación. Estas habilidades son fundamentales para el
    desarrollo de los niños y pueden tener un impacto positivo en su rendimiento
    académico."
      imageUrl="logo.png"
    />
    <CardComponent
      title="Fomento de la creatividad"
      description=" El skateboarding es una forma de expresión artística para muchos jóvenes. Les brinda la oportunidad de desarrollar su creatividad a través del diseño de trucos y la personalización de sus tablas.
"
      imageUrl="logo.png"
    />
    <CardComponent
      title="Comunidad y valores"
      description="Fomentar una comunidad de skaters en nuestras comunidades puede promover valores como el respeto, la colaboración y la autoconfianza. Los jóvenes pueden aprender a enfrentar desafíos y superar obstáculos juntos.
"
      imageUrl="logo.png"
    />
    <CardComponent
      title="Accesibilidad"
      description="Aunque el skate a menudo se asocia con un deporte de élite,
      este proyecto se aseguraría de que esté al alcance de todos los jóvenes
      independientemente de su nivel socioeconómico. Proporcionaremos tablas y
      equipos para aquellos que lo necesiten."
      imageUrl="logo.png"
    />
  </div>

  <TitleComponentVue
    titleContainerStyle="titlecontainerstyleabout"
    mainTitleStyle="maintitlestyleabout"
    subtitleStyle="subtitlestyleabout"
    mainTitle="Tenemos al mejor profesor"
    subtitle="Vicente Lloret"
  />
  
  <diV class="profesor">
    <ProfesorSkate></ProfesorSkate>
    <BotonConBordes msg="Ver clase" @click="refreshPage"></BotonConBordes>
    <InstagramIframe></InstagramIframe>
  </diV>

  <TitleComponentVue
    titleContainerStyle="titlecontainerstyleabout"
    mainTitleStyle="maintitlestyleabout"
    subtitleStyle="subtitlestyleabout"
    mainTitle="Fedérate con nosotros"
    subtitle="La Safor Club de Skateboard"
  />
  <div class="federacionimg"><img src="tituloClub.jpg"  /></div>
  <div class="federacion">
    <CardComponent
      title="Deporte Olímpico"
      description="El skateboarding es deporte olímpico desde los juegos Olímpicos de Tokio 2020,
     así que es un buen momento para aprender a practicar skate y cuando seamos verdaderos as de
    este astuto deporte entonces pensar en federarnos "
      imageUrl="logo.png"
    />
    <CardComponent
      title="Estar federado"
      description="Federarse en un deporte significa unirse a la federación oficial del mismo,
       aceptando sus reglas y requisitos. Proporciona acceso a competiciones oficiales,
        reconocimiento de logros y contribuye al desarrollo del deporte a través de la
        participación en el sistema organizativo de la federación."
      imageUrl="logo.png"
    />
    <CardComponent
      title="Seguro federativo"
      description="Un seguro federativo es una póliza de seguro asociada a la afiliación a una federación deportiva. Al federarse,
       los deportistas o clubes suelen adquirir este seguro que proporciona cobertura para lesiones o accidentes ocurridos durante
        la práctica oficial del deporte, ya sea en entrenamientos o competiciones organizadas por la federación.  "
      imageUrl="logo.png"
    />
  </div>
  <WhatsAppButton/>
</template>

<script>
import NavBarVue from "@/components/NavBar.vue";
import VideoBackComponentVue from "@/components/VideoBackComponent.vue";
import BotonConBordes from "@/components/BotonConBordes.vue";
import TitleComponentVue from "@/components/TitleComponent.vue";
import InstagramIframe from "@/components/InstagramIframe.vue";
import CardComponent from "@/components/CardComponent.vue";
import WhatsAppButton from "@/components/WhatsAppButton.vue";
import ProfesorSkate from "@/components/ProfesorSkate.vue";
export default {
  components: {
    InstagramIframe,
    VideoBackComponentVue,
    BotonConBordes,
    NavBarVue,
    TitleComponentVue,
    CardComponent,
    WhatsAppButton,
    ProfesorSkate
  },
  methods: {
    refreshPage() {
      location.reload();
    }
  },
  data() {
    return {
      titleconatinerabout: "titleconatinerabout",
    };
  },

};
</script>

<style scoped>
.cards {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  background-color: rgba(227, 245, 245, 0);
  margin: 15px;
  font-family: sans-serif;
}
.federacion {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  margin: 15px;
  background-color: rgba(255, 255, 255, 0);
}

.federacionimg{
  display: flex;
  justify-content: center;
}
.federacionimg img {
  width: 35%;
  border-radius: 20px;
}
.profesor {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}
@media screen and (max-width: 1000px) {
  .federacionimg img {
    width: 80%;
    border-radius: 20px;
  }
}
</style>
