<template>
    <nav :class="{ navbarstyle }">
    <div class="logo">
      <button @click="redirectToHome">
        <img :src="logoSource" alt="Logo" />
      </button>
      <!-- Agrega tus enlaces de navegación aquí -->
    </div>
  </nav>
  </template>
  
  <script>
  export default {
  data() {
    return {
      // Agrega la ruta de la página de inicio
      homeRoute: "/",
    };
  },
  methods: {
    redirectToHome() {
      // Redirige a la página de inicio al hacer clic en el botón del logo
      this.$router.push(this.homeRoute);
    },
  },
  props: {
    navbarstyle: String, // Asegúrate de que navbarstyle sea una propiedad
    logoSource: String, // Asegúrate de que logoSource sea una propiedad
  },
};
</script>
  
  <style scoped>
.navbarstyle {
  top: 0%;
  position: absolute;
  width: 100%;
  display: flex;
  align-items: left;
  padding: 0%;
  margin: 0%;
  background-color: #3eee0300;
  left: 0%;
}
.logo {
  text-align: left;
  background-color: #ffc80000;
  width: 100%; /* Asegura que ocupa el ancho completo en dispositivos móviles */
}
button{
    background-color: rgba(0, 255, 255, 0);
    border: none;
  }
@media (max-width: 768px) {
  .navbarDef {
    top: 0%;
    position: absolute;
    width: 100%;
    display: flex;
    align-items: left;
    margin-left: 0%;
    background-color: #84ee0300;
  }
  .logo {
    text-align: center;
    background-color: #ffc80000;
    width: 100%; /* Asegura que ocupa el ancho completo en dispositivos móviles */
  }


}

/* Puedes agregar estilos adicionales según tus necesidades */
</style>
  